<template>
  <div>
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button  size="mini" type="primary" @click="search">刷新</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="searchForm.username" size="mini" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.userTypeId" size="mini" @change="a" placeholder="请选择嘉宾类型" multiple collapse-tags clearable>
                <el-option  v-for="item in userTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.yyCompanyId" size="mini" placeholder="请选择邀宾单位/部门">
                <el-option v-for="item in yyCompanyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.createUserId" size="mini" placeholder="请选择管理员">
                <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.hyId" size="mini" placeholder="请选择会议">
                <el-option v-for="item in plateList" :key="item.id" :label="item.title" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.status" size="mini" placeholder="请选择参会状态">
                <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.hotelId" size="mini" placeholder="请选择酒店">
                <el-option v-for="item in hotelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.qy" size="mini" placeholder="请选择付费方式">
                <el-option v-for="item in qyList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>

           
            <el-form-item label="">
              <el-input v-model="searchForm.phone" size="mini" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="searchForm.post" size="mini" placeholder="请输入职务职级"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="searchForm.company" size="mini" placeholder="请输入单位"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.workGroup" size="mini" placeholder="请选择部门/组">
                <el-option v-for="item in workGroupList" :key="item.value" :label="item.title" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.country" size="mini" placeholder="请选择国家">
                <el-option v-for="item in countryList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.handlerId" size="mini" placeholder="请选择联络员">
                <el-option v-for="item in handlerUserList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="修改时间:">
              <el-date-picker
                v-model="date.date3"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="创建时间:">
              <el-date-picker
                v-model="date.date4"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>


          </el-form>
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-select v-model="searchForm.xingchengZt" size="mini" placeholder="请选择行程状态">
                <el-option v-for="item in xingchengZtList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="抵青时间:">
              <el-date-picker
                v-model="date.date1"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="离青时间:">
              <el-date-picker
                v-model="date.date2"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button  size="mini" type="primary" @click="search">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button  size="mini" type="primary" @click="clear">清空</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="sendYQSMSBatch">发送邀请短信</el-button>
            </el-form-item>
<!--            <el-form-item>-->
<!--              <el-button type="primary" size="mini" @click="sendYQEmailBatch">发送邀请邮件</el-button>-->
<!--            </el-form-item>-->
            <el-form-item>
              <el-button type="info" size="mini">发送邀请邮件</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="showAddDialogForm">新增嘉宾</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="handleExcelImp">excel导入</el-button>
            </el-form-item>

          </el-form>

          <!-- 导出Excel -->
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button type="primary" size="mini" @click="exportExcel">按搜索条件导出Excel</el-button>
            </el-form-item>
            <el-form-item>
              <el-button :plain="true" type="primary" size="mini" @click="exportExcelNew(10)">导出总表</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain type="primary" size="mini" @click="exportExcelNew(11)">导出政务贵宾表</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain type="primary" size="mini" @click="exportExcelNew(12)">导出政务嘉宾表</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain type="primary" size="mini" @click="exportExcelNew(13)">导出行业贵宾表</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain type="primary" size="mini" @click="exportExcelNew(14)">导出行业嘉宾表</el-button>
            </el-form-item>
          </el-form>

        </div>
      </el-col>
    </el-row>

    <el-table
    :cell-style="columnStyle"
      :data="tableData"
      ref="elTable"
      :header-cell-style="{backgroundColor:'#f4f4f5',textAlign:'center'}"
      :height="tableHeight"
      border
      style="width: 100%">
      <el-table-column
        type="selection"
        align="center"
        width="55">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="id"
        label="序号"
        type="index"
        width="80">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="sortBy"
        label="排序"
        min-width="80">
        <template slot-scope="scope">
          <el-button slot="reference" type="text" size="small" style="color: #606266" @click="showEditSortBy(scope.row, scope.$index)">{{ scope.row.sortBy }}</el-button>
<!--          <el-popover-->
<!--            placement="top-start"-->
<!--            trigger="manual"-->
<!--            v-model="editSortBy.v['v'+scope.$index]"-->
<!--            width="160">-->
<!--            <el-input v-model="editSortBy.sortByEditValue" size="mini" placeholder="请填写排序"></el-input>-->
<!--            <div style="text-align: right; margin: 0px; margin-top: 10px;">-->
<!--              <el-button type="primary" size="mini" @click="updateSortBy(scope.row, scope.$index)">确定</el-button>-->
<!--            </div>-->
<!--            <el-button slot="reference" type="text" size="small" style="color: #606266" @click="showEditSortBy(scope.row, scope.$index)">{{ scope.row.sortBy }}</el-button>-->
<!--          </el-popover>-->
        </template>
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="username"
        label="姓名"
          show-overflow-tooltip>
      </el-table-column>

     
      <el-table-column
        align="center"
        prop="company"
        label="单位"
        min-width="200">
      </el-table-column>
      <el-table-column
        align="center"
        prop="post"
        label="职务"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="userTypeName"
        label="嘉宾类型"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="hyId"
        align="left"
        label="已确认板块"
          show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="queren(scope.row,1)">已确认板块</el-button>

          <!-- <div v-html="parsePlate(scope.row,1)"></div> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="hyId"
        align="left"
        label="待确认板块"
          show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="queren(scope.row,0)">待确认板块</el-button>

          <!-- <div v-html="parsePlate(scope.row,0)"></div> -->
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="yyCompanyName"
        label="邀约单位"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="参会状态"
          show-overflow-tooltip>
        <template slot-scope="scope">
          <template v-if="scope.row.status == -1">取消</template>
          <template v-else-if="scope.row.status == 0">审核中</template>
          <template v-else-if="scope.row.status == 1">审核通过</template>
          <template v-else-if="scope.row.status == 2">暂不确定</template>
          <template v-else-if="scope.row.status == 3">确认参加</template>
          <template v-else-if="scope.row.status == -1">取消参加</template>
          <template v-else-if="scope.row.status == -2">嘉宾拒绝</template>
          <template v-else-if="scope.row.status == -3">已删除</template>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="sxList"
        label="随行人员"
        min-width="200">
        <template slot-scope="scope">
          <span v-html="fmtSx(scope.row)"></span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="note2"
        label="嘉宾活动安排备注"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="note"
        label="嘉宾个人需求备注"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="xingchengZt"
        label="当前行程状态"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="hotelName"
        label="酒店"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="yq"
        label=""
          show-overflow-tooltip>
        <template slot-scope="scope">
          <template v-if="scope.row.yq == 0">7天内未去过中高风险地区</template>
          <template v-else-if="scope.row.yq == 1">7天内去过中高风险地区</template>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="arriveTime"
        label="抵青时间"
          show-overflow-tooltip>
        <template slot-scope="scope">
          {{fmtDate2(scope.row.arriveTime)}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="arriveMode"
        label="抵达方式"
        min-width="100">
      </el-table-column>
      <el-table-column
        align="center"
        prop="frequency"
        label="抵达航班/车次信息"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="carnum"
        label="抵达车牌"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="arriveStation"
        label="接站口"
        min-width="100">
      </el-table-column>
      <el-table-column
        align="center"
        prop="arriveTime"
        label="离青时间"
          show-overflow-tooltip>
        <template slot-scope="scope">
          {{fmtDate2(scope.row.backTime)}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="backMode"
        label="抵达方式"
        min-width="100">
      </el-table-column>
      <el-table-column
        align="center"
        prop="backFrequency"
        label="抵达航班/车次信息"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="backCarnum"
        label="抵达车牌"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="lkdd"
        label="送站口"
        min-width="100">
      </el-table-column>


      <el-table-column
        align="center"
        prop="createTime"
        label="创建时间"
        min-width="160">
        <template slot-scope="scope">
          {{fmtDate(new Date(scope.row.createTime*1000))}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createUserName"
        label="创建人"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        align="center"
        prop="updateTime"
        label="最新修改时间"
        min-width="160">
        <template slot-scope="scope">
          {{fmtDate(new Date(scope.row.updateTime*1000))}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="updateUserName"
        label="最新修改人"
          show-overflow-tooltip>
      </el-table-column>

      <el-table-column
        align="center"
        prop="address"
        fixed="right"
        label="邀请函"
          show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="genYYH(scope.$index, scope.row)">生成邀请函</el-button>
        </template>
      </el-table-column>

      <!-- <el-table-column
      fixed="right"
        align="center"
        prop="address"
        label="审核"
        min-width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="submitSH(scope.$index, scope.row)" v-if="scope.row.status==3">审核</el-button>
        </template>
      </el-table-column> -->

      <el-table-column
      fixed="right"
        align="center"
        prop="address"
        label="操作"
        min-width="180">
        <template slot-scope="scope">
          <el-button size="mini" style="margin-left: 0;margin-top: 10px;" type="success" @click="submitSH(scope.$index, scope.row)" v-if="scope.row.status==3">审核</el-button>

<!--          <el-button size="mini" type="success" @click="submitSH(scope.$index, scope.row)" v-if="scope.row.status==0||scope.row.status==2||scope.row.status==3">审核</el-button>-->
          <el-button size="mini" style="margin-left: 0;margin-top: 10px;" type="primary" @click="handleEdit(scope.$index, scope.row)">基本信息修改</el-button>
          <el-button size="mini" style="margin-left: 0;margin-top: 10px;" type="primary" @click="handleEditDL(scope.$index, scope.row)">修改抵离信息</el-button>
          <el-button size="mini" style="margin-left: 0;margin-top: 10px;" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog :title="editForm.id==0?'新增嘉宾':'修改嘉宾'" :visible.sync="editFormVisible" width="80%" top="1vh" :close-on-click-modal="false">
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item label="嘉宾类型" label-width="120px" prop="userTypeId">
          <el-radio v-for="(item,index) in userTypeList" v-model="editForm.userTypeId" :label="item.id" v-if="index>0" @change="onUserTypeChange">{{ item.name }}</el-radio>
        </el-form-item>
        <el-row v-if="addShowFlag">
          <el-col :span="8">
           <!-- <el-form-item label="绑定用户" label-width="120px" prop="frontUserId">
              <el-select v-model="editForm.frontUserId" size="small" clearable filterable placeholder="直接选择或搜索选择">
                <el-option
                  v-for="item in frontUserList"
                  :key="item.id"
                  :label="item.nickname+'('+item.phoneNum+')'"
                  :value="item.id">
                </el-option>
              </el-select>

            </el-form-item> -->
          </el-col>
          <el-col :span="8">
            <el-form-item label="绑定联络员" label-width="120px" prop="handlerId">
              <el-select v-model="editForm.handlerId" size="small" clearable filterable placeholder="直接选择或搜索选择">
                <el-option
                  v-for="(item,index) in userList"
                  :key="item.id"
                  :label="item.nickname+'('+item.username+')'"
                  :value="item.id" v-if="index>0">
                </el-option>
              </el-select>
<!--              <el-input size="small" v-model="editForm.handlerId" autocomplete="off" placeholder="直接选择或搜索选择"></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>

        <el-row v-if="editForm.userTypeId==6">
          <el-col :span="8">
            <el-form-item label="贵宾" label-width="120px" prop="ppid">
              <el-select size="small" v-model="editForm.ppid" filterable placeholder="请选择">
                <el-option
                  v-for="item in gbList"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
          <el-col :span="8"></el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" label-width="120px" prop="username">
              <el-input size="small" v-model="editForm.username" autocomplete="off" placeholder="请输入参会人员姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" label-width="120px" prop="gender">
              <el-radio-group size="small" v-model="editForm.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系方式" label-width="120px" prop="phone">
              <el-input size="small" v-model="editForm.phone" autocomplete="off" placeholder="请输入参会人联系方式" maxlength="11"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话类型" label-width="120px" prop="phoneType">
              <el-select
                v-model="editForm.phoneType"
                size="small"
                autocomplete="off"
                filterable
                allow-create
                default-first-option
                placeholder="选择联系电话类型">
                <el-option key="本人" label="本人" value="本人"></el-option>
                <el-option key="助理" label="助理" value="助理"></el-option>

              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱" label-width="120px" prop="email">
              <el-input size="small" v-model="editForm.email" autocomplete="off" placeholder="请输入参会人邮箱" maxlength="50"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="单位" label-width="120px" prop="company">
              <el-input size="small" v-model="editForm.company" autocomplete="off" placeholder="请输入参会人员单位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="职务" label-width="120px" prop="handlerId">
              <el-input size="small" v-model="editForm.post" autocomplete="off" placeholder="请输入参会人员职务"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="editForm.userTypeId==1||editForm.userTypeId==2">
          <el-col :span="8">
            <el-form-item label="迎送领导" label-width="120px" prop="yslingdao">
              <el-input size="small" v-model="editForm.yslingdao" autocomplete="off" placeholder="如需,请填入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="迎送备注" label-width="120px" prop="ysdesc">
              <el-input size="small" v-model="editForm.ysdesc" autocomplete="off" placeholder="如需,请填入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="国籍" label-width="120px" prop="country">
              <el-input size="small" v-model="editForm.country" autocomplete="off" placeholder="国籍"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="证件号码" label-width="120px" prop="idcard">
              <el-input size="small" v-model="editForm.idcard" autocomplete="off" placeholder="请输入证件号码,身份证或护照"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="证件" label-width="120px" prop="idCardType">
              <el-radio-group size="small" v-model="editForm.idCardType">
                <el-radio :label="0">身份证</el-radio>
                <el-radio :label="1">护照</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="地址" label-width="120px" prop="sheng">
              <el-cascader
                size="small"
                clearable
                class="elCacader"
                v-model="selectValue"
                :props="defultProp"
                :options="address"
                @change="handleAddressChange"
              ></el-cascader>
              {{selectValue}}
            </el-form-item>
          </el-col>
        </el-row>
        <!--
        <el-form-item label="邀请参会" label-width="120px" prop="hyIds">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="editForm.hyIds" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="(item,index) in plateList" :label="item.id" name="type" v-if="index > 0">{{item.title}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        -->
        <el-form-item label="邀请参会" label-width="120px" prop="hyIds" v-if="editForm.userTypeId != 8">
          <el-tabs v-model="tabsActiveName" type="border-card">
            <el-tab-pane v-for="(item,index) in plateWithGroupList" :label="item.title" :name="item.title" :key="index">
              <el-checkbox-group v-model="editForm.hyIds" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="(item2,index2) in item.plateList" :label="item2.id" :key="index2" name="type">{{item2.title}}</el-checkbox>
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="邀请参会" label-width="120px" prop="hyIds" v-if="editForm.userTypeId == 8">
          <el-tabs v-model="zyTabsActiveName" type="border-card">
            <el-tab-pane v-for="(item,index) in zyPlateList" :label="item.title" :name="item.title" :key="index">
              <el-checkbox-group v-model="editForm.hyIds" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="(item2,index2) in item.plateList" :label="item2.id" :key="index2" name="type">{{item2.title}}</el-checkbox>
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="邀约单位/部门" label-width="120px" prop="yyCompanyId">
          <el-radio v-for="(item,index) in yyCompanyList" v-model="editForm.yyCompanyId" :label="item.id" v-if="index>0">{{ item.name }}</el-radio>
        </el-form-item>
        <el-form-item label="部门组" label-width="120px" prop="workGroup" v-if="editForm.userTypeId == 10">
          <el-radio v-for="(item,index) in workGroupList" v-model="editForm.workGroup" :label="item.title" v-if="index>0">{{ item.title }}</el-radio>
        </el-form-item>
        <el-form-item label="付费方式" label-width="120px" prop="qy">
          <el-radio v-for="(item,index) in qyList" v-model="editForm.qy" :label="item.value" v-if="index>0">{{ item.label }}</el-radio>
          <el-input size="small" v-model="editForm.qyOther" autocomplete="off" placeholder="选择其他,请填写" style="width: 300px;"></el-input>
          <div style="color: red">部分嘉宾费用由承办方全部承担，原则上青岛市内嘉宾不承担住宿费。</div>
        </el-form-item>
        <el-form-item label="嘉宾活动安排备注" label-width="130px" prop="note2">
          <el-input size="small" v-model="editForm.note2" autocomplete="off" placeholder="填写嘉宾是否有签约，座谈、颁奖、主持等活动"></el-input>
        </el-form-item>
        <el-form-item label="嘉宾个人需求备注" label-width="130px" prop="note">
          <el-input size="small" v-model="editForm.note" autocomplete="off" placeholder="请输入备注信息,如宗教信仰民族等"></el-input>
        </el-form-item>
        <el-form-item label="是否参会" label-width="120px" prop="status">
          <el-radio v-model="editForm.status" :label="2">待定</el-radio>
          <el-radio v-model="editForm.status" :label="3">确认参加</el-radio>
        </el-form-item>
        <!-- <el-form-item label="疫情登记" label-width="120px" prop="yq">
          <el-radio v-model="editForm.yq" :label="0">7天内未去过中高风险地区</el-radio>
          <el-radio v-model="editForm.yq" :label="1">7天内去过中高风险地区</el-radio>
        </el-form-item> -->
        <el-form-item label="人脸识别图片" label-width="120px" prop="faceImg">
          <el-upload
            class="avatar-uploader"
            name="file"
            :action="uploadActionUrl"
            accept=".png,.jpeg,.jpg"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-error="handleAvatarError">
            <img v-if="editForm.faceImg" :src="editForm.faceImg" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
<!--        <el-form-item label="邀约类型" label-width="120px" prop="yyType">-->
<!--          <el-radio v-model="editForm.yyType" :label="1">正常嘉宾</el-radio>-->
<!--          <el-radio v-model="editForm.yyType" :label="2">国家广电总局邀约</el-radio>-->
<!--          <el-radio v-model="editForm.yyType" :label="3">山东省广电局邀约</el-radio>-->
<!--          <el-radio v-model="editForm.yyType" :label="4">媒体记者</el-radio>-->
<!--          <el-radio v-model="editForm.yyType" :label="5">工作人员</el-radio>-->
<!--          <el-radio v-model="editForm.yyType" :label="6">观众</el-radio>-->
<!--        </el-form-item>-->
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button type="primary" @click.native.prevent="submitForm" :loading="loading">{{ editForm.id==0?'添 加':'修 改' }}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="genImgTitle" :visible.sync="yyhDialogVisible" width="80%" :close-on-click-modal="false">
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-row>
          <el-col :span="8">
            <vue-canvas-poster v-if="genPoster" :widthPixels="0" :painting="painting" @success="genYYHSuccess" @fail="genYYHFail"></vue-canvas-poster>
          </el-col>
          <el-col :span="8">
            <div v-if="XCXScanTime" style="font-size: 18px;font-weight: 500;margin-bottom: 10px;">(已被扫码)</div>
            <div v-else style="font-size: 18px;font-weight: 500;margin-bottom: 10px;">(未被扫码)</div>
            <img :src="yyhImg" style="width: 360px;height: 630px;mode: aspectFill"></img>
          </el-col>
          <el-col :span="8" style="font-size: 20px;float: right;">
            <div v-if="H5ScanTime" style="font-size: 18px;font-weight: 500;margin-bottom: 10px;">(H5邀请函已被打开过)</div>
            <div v-else style="font-size: 18px;font-weight: 500;margin-bottom: 10px;">(H5邀请函未被打开过)</div>
            短信{{yyInfoSendStatus}}:<br>
            {{yyInfoSMS}}

          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="yyhDialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click.native.prevent="sendYYSMS" :loading="loading">发送邀请短信</el-button>
<!--       <!-- <el-button type="primary" @click.native.prevent="sendYYEmail" :loading="loading">发送邀请邮件</el-button> -->-->
        <el-button type="info" :loading="loading">发送邀请邮件</el-button>
        <el-button type="primary" @click.native.prevent="saveImg" :loading="loading">保存图片</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改抵离信息" :visible.sync="editDLFormVisible" width="80%" :close-on-click-modal="false">
      <el-form :model="editDLForm" ref="editDLForm" :rules="editDLRules">
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" label-width="120px" prop="username">
              <el-input size="small" :disabled="true" v-model="editDLForm.username" autocomplete="off" placeholder="请输入参会人员姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式" label-width="120px" prop="phone">
              <el-input size="small" :disabled="true" v-model="editDLForm.phone" autocomplete="off" placeholder="请输入参会人联系方式" maxlength="11"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" label-width="120px" prop="gender">
              <el-radio-group size="small" :disabled="true" v-model="editDLForm.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="预计抵青时间" label-width="120px" prop="esArriveTime">
              <el-date-picker
                v-model="dlDate.date1"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="datetime"
                placeholder="请选择日期和时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="抵青时间" label-width="120px" prop="arriveTime">
              <el-date-picker
                v-model="dlDate.date2"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="datetime"
                placeholder="请选择日期和时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="抵青方式" label-width="120px" prop="arriveMode">
              <el-radio-group v-model="editDLForm.arriveMode">
                <el-radio label="飞机">飞机</el-radio>
                <el-radio label="高铁">高铁</el-radio>
                <el-radio label="自驾">自驾</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" label-width="0px" prop="carnum">
              <el-input size="small" v-model="editDLForm.carnum" autocomplete="off" placeholder="如选择自驾,请输入车牌号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="抵青班次" label-width="120px" prop="frequency">
              <el-input size="small" v-model="editDLForm.frequency" autocomplete="off" placeholder="请输入抵青班次"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="抵青接站口" label-width="120px" prop="arriveStation">
              <el-radio-group v-model="editDLForm.arriveStation">
                <el-radio v-for="(item,index) in jzkList" :label="item.label">{{ item.value }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="离青时间" label-width="120px" prop="backTime">
              <el-date-picker
                v-model="dlDate.date3"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="datetime"
                placeholder="请选择日期和时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="离青方式" label-width="120px" prop="backMode">
              <el-radio-group v-model="editDLForm.backMode">
                <el-radio label="飞机">飞机</el-radio>
                <el-radio label="高铁">高铁</el-radio>
                <el-radio label="自驾">自驾</el-radio>
              </el-radio-group>
            </el-form-item>
            </el-col>
            <el-col :span="8">
            <el-form-item label="" label-width="0px" prop="backCarnum">
              <el-input size="small" v-model="editDLForm.backCarnum" autocomplete="off" placeholder="如选择自驾,请输入车牌号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="离青班次" label-width="120px" prop="backFrequency">
              <el-input size="small" v-model="editDLForm.backFrequency" autocomplete="off" placeholder="请输入离青班次"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="离青送站口" label-width="120px" prop="lkdd">
              <el-radio-group v-model="editDLForm.lkdd">
                <el-radio v-for="(item,index) in jzkList" :label="item.label">{{ item.value }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="当前付费方式" label-width="120px" prop="">
              {{ editDLForm.dqqy }}
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="分配酒店" label-width="120px" prop="hotelId">
              <el-radio-group v-model="editDLForm.hotelId">
                <el-radio v-for="(item,index) in hotelList" :label="item.id" v-if="index > 0">{{ item.name }}</el-radio>
                <el-radio :label="0">暂不分配</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="用餐点" label-width="120px" prop="eatHotelId">
              <el-radio-group v-model="editDLForm.eatHotelId">
                <el-radio v-for="(item,index) in hotelList" :label="item.id" v-if="index > 0">{{ item.name }}</el-radio>
                <el-radio :label="0">暂不分配</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="酒店床位备注" label-width="120px" prop="jdNote">
              <el-input size="small" v-model="editDLForm.jdNote" autocomplete="off" placeholder="请填写酒店入住信息"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="当前行程状态" label-width="120px" prop="xingchengZt">
              <el-radio-group v-model="editDLForm.xingchengZt">
                <el-radio v-for="(item,index) in xingchengZtList" :disabled="getXingchengZtDisabled(item.label)" :label="item.label" v-if="index > 0">{{ item.value }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="备注说明" label-width="120px" prop="dlNote">
              <el-input size="small" v-model="editDLForm.dlNote" autocomplete="off" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>


      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDLFormVisible = false">关 闭</el-button>
        <el-button type="primary" @click.native.prevent="submitEditDL" :loading="editDLLoading">修改</el-button>
      </div>
    </el-dialog>

    <el-dialog title="excel导入" :visible.sync="excelImp.dialogVisible" width="60%" :close-on-click-modal="false">
      <el-form>
        <el-row>
          <el-col :span="24">
            <a :href="baseUrl+'/template.xlsx'" target="_blank">点击下载导入模板</a>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;">
          <el-col :span="24">
            <el-upload
              class="upload-demo"
              ref="upload"
              name="file"
              :action="excelImp.action"
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              :file-list="excelImp.fileList"
              :auto-upload="false"
              :on-success="onUploadSuccess"
              :on-error="onUploadError">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
<!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx</div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="excelImp.dialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click.native.prevent="submitUpload" :loading="excelImp.loading">立即导入</el-button>
      </div>
    </el-dialog>
    <queren ref="queren" @fetch-data="getDataList" />

  </div>
</template>

<script>
import queren from "../modeltwo/canhuitwo.vue"; //确认参加

import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getUserList, addUser, updateUser, deleteUser } from "@/api/user";
import { getUserTypeList } from "../../../api/user_type";
import { getYycompanyList } from "../../../api/yy_company";
import { getFrontUserList } from "../../../api/front_user";

import {
  getParticipateList,
  addParticipate,
  updateParticipate,
  deleteParticipate,
  updateDLInfo,
  updateSortBy,
  shenhe,
  getParticipateGB,
} from "@/api/participate";
import { getPlateList, getPlateListWithGroup } from "../../../api/plate";
import { getSystemSet } from "../../../api/system_setting";
import { getHotelList } from "../../../api/hotel";
import { getAllUserList } from "../../../api/user";
import json from "@/assets/address.json";
import {
  getInvitationCode,
  sendYYSMS,
  sendYYEmail,
  sendYYSMSBatch,
  sendYYEmailBatch,
} from "../../../api/invitation";
import axios from "axios";
import { getToken } from "../../../common/utils/auth";

export default {
  name: "participateManage",
  components: { queren },

  comments: {
    // queren,
    // VueCanvasPoster
  },
  data() {
    return {
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,
        frontUserId: "",
        handlerId: "",
        username: "",
        phone: "",
        phoneType: "",
        email: "",
        gender: "",
        company: "",
        yslingdao: "",
        ysdesc: "",
        country: "中国",
        idcard: "",
        idCardType: 0,
        sheng: "",
        shi: "",
        xian: "",
        hyIds: [],
        hyId: "",
        userTypeId: "",
        yyCompanyId: "",
        workGroup: "",
        qy: "",
        note2: "",
        note: "",
        status: "",
        yq: "",
        yyType: "",
        faceImg: "",
        ppid: "",
        qyOther: "",
        post: "",
      },
      gbList: [],
      editRules: {
        username: [
          { required: true, message: "请输入参会人员姓名", trigger: "blur" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        company: [
          { required: true, message: "请输入参会人员单位", trigger: "blur" },
        ],
        post: [
          { required: true, message: "请输入参会人员职务", trigger: "blur" },
        ],
        // country: [{ required: true, message: "请输入国籍", trigger: "blur" }],
        // sheng: [{ required: true, message: "请选择地址", trigger: "blur" }],
        // hyIds: [
        //   { required: true, message: "请选择邀请参加会议", trigger: "blur" },
        // ],
        userTypeId: [
          { required: true, message: "请选择嘉宾类型", trigger: "blur" },
        ],
        qy: [{ required: true, message: "请选择付费方式", trigger: "blur" }],
        status: [
          { required: true, message: "请选择是否参会", trigger: "blur" },
        ],
        yq: [
          {
            required: true,
            message: "请选择7天内是否到过疫情地区",
            trigger: "blur",
          },
        ],
        yyType: [
          { required: true, message: "请选择邀约类型", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: validPhone,
            message: "手机号码格式错误",
            trigger: "blur",
          },
        ],
        email: [
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur"] },
        ],
      },
      loadingDiv: null,
      searchForm: {
        cardId: "",
        phone: "",
        username: "",
        post: "",
        userTypeId: [],
        company: "",
        hyId: "",
        status: "",
        yyCompanyId: "",
        workGroup: "",
        hotelId: "",
        country: "",
        qy: "",
        handlerId: "",
        createUserId: "",
        xingchengZt: "",
        arriveTime1: "",
        arriveTime2: "",
        backTime1: "",
        backTime2: "",
        updateTime1: "",
        updateTime2: "",
        createTime1: "",
        createTime2: "",
        userTypeIdList: [],
      },

      date: {
        date1: "",
        date2: "",
        date3: "",
        date4: "",
      },
      plateList: [],
      statusList: [
        // 状态  -1取消  1通过 2暂不确定 -2嘉宾拒绝 -3删除嘉宾
        { value: "", label: "请选择参会状态" },
        { value: 99, label: "审核+确定" },
        { value: 0, label: "审核中" },
        { value: 1, label: "审核通过" },
        { value: 3, label: "确定参加" },
        { value: 2, label: "暂不确定" },
        { value: -1, label: "取消参加" },
        { value: -2, label: "嘉宾拒绝" },
        { value: -3, label: "已删除" },
      ],
      yyCompanyList: [],
      workGroupList: [],
      hotelList: [],
      countryList: [
        { value: "", label: "请选择国家" },
        { value: "中国", label: "中国" },
        { value: "非中国", label: "非中国" },
      ],
      qyList: [
        { value: "", label: "请选择付费方式" },
        { value: "全部承担", label: "全部承担" },
        { value: "承担住宿餐饮", label: "承担住宿餐饮" },
        { value: "仅餐饮", label: "仅餐饮" },
        { value: "全自费", label: "全自费" },
        { value: "其他", label: "其他" },
        // {value: '食宿补差', label: '食宿补差'},
        // {value: '住宿补差', label: '住宿补差'},
      ],
      handlerUserList: [],
      userList: [],
      xingchengZtList: [
        { value: "", label: "请选择行程状态" },
        { value: "在途", label: "在途" },
        { value: "到融创茂", label: "到融创茂" },
        { value: "入住酒店", label: "入住酒店" },
        { value: "已入场", label: "已入场" },
        { value: "离店", label: "离店" },
        { value: "离青", label: "离青" },
      ],
      userTypeList: [],
      address: [],
      selectValue: "",
      defultProp: {
        label: "name",
        value: "name",
        children: "sub",
      },
      isIndeterminate: false,
      checkAll: false,
      frontUserList: [],

      genPoster: false,
      yyhDialogVisible: false,
      genImgTitle: "",
      yyhImg: "",
      imgQrcodeContent: "",
      imgUserName: "",
      genYYHRow: null,
      painting: {
        width: `1080px`,
        height: `1890px`,
        background: "#f4f5f7",
        views: {},
      },
      yyInfoRow: "",
      yyInfoCode: "",
      XCXScanTime: "",
      H5ScanTime: "",
      yyInfoSMS: "",
      yyInfoSendStatus: "",
      plateWithGroupList: [],

      editDLFormVisible: false,
      editDLLoading: false,
      editDLForm: {
        id: "",
        pid: "",
        username: "",
        phone: "",
        gender: "",

        esArriveTime: "", // 预计抵青时间
        arriveTime: "", // 抵青时间

        arriveMode: "", // 到达方式 '飞机', '高铁', '自驾'
        carnum: "", // 车牌号

        frequency: "", // 班次
        arriveStation: "", // 抵青接站口

        backTime: "", // 离青时间

        backMode: "", // 离青方式
        backCarnum: "", // 离青车牌号

        backFrequency: "", // 离青班次
        lkdd: "", // 离青送站口

        dqqy: "", // 当前权益

        hotelId: "", // 分配酒店
        eatHotelId: "", // 分配吃饭地点

        jdNote: "", // 酒店床位备注

        xingchengZt: "", // 行程状态
        xingchengZtCurrent: "", // 修改之前行程状态

        dlNote: "", // 抵离备注
      },
      editDLRules: {},
      addShowFlag: false,

      dlDate: {
        date1: "",
        date2: "",
        date3: "",
      },
      jzkList: [
        // 接/送站口
        { label: "胶东国际机场", value: "胶东国际机场" },
        { label: "青岛北站", value: "青岛北站" },
        { label: "青岛站", value: "青岛站" },
        { label: "青岛西站", value: "青岛西站" },
      ],

      editSortBy: {
        sortByEditValue: "",
        pid: "",
      },

      excelImp: {
        dialogVisible: false,
        loading: false,
        fileList: [],
        action: "",
        loadingDiv: "",
      },

      baseUrl: "",
      uploadActionUrl: "",
      avatarUploadLoadingDiv: "",

      tabsActiveName: "",

      zyPlateList: [],
      zyTabsActiveName: "",
      userTypeIdBak: -1,
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    // for(let i=0;i<100;i++){
    //   this.editSortBy.v["v"+i] = true;
    // }

    this.getDataList(this.currentPage);

    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight) {
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight =
        document.documentElement.clientHeight - 150 - offsetHeight;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight =
          document.documentElement.clientHeight - 150 - offsetHeight;
      });
    };

    this.address = json;

    this.init();

    let t = getToken();
    this.excelImp.action =
      this.$adminApiUrl + "/participate/excel-imp?userType=-1&_t=" + t;
    this.baseUrl = this.$baseUrl;
    this.uploadActionUrl = this.$adminApiUrl + "/uploadToQiNiu?_t=" + t;
  },
  methods: {
    queren(row, status) {
      this.$refs["queren"].showEdit(row, status);
      // this.$refs["queren"].parsePlate(row, status);
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1 || columnIndex == 2 || columnIndex == 3) {
        return "background:	#FFF8DC;";
      }
    },
    fmtSx(row) {
      if (util.isEmpty(row.sxList)) {
        return "";
      }

      let sxStr = "";
      let sxList = row.sxList;
      for (let i in sxList) {
        sxStr += sxList[i].username + "(" + sxList[i].phone + ")<br />";
      }
      console.log(sxStr);
      return sxStr;
    },
    getParticipateGB() {
      getParticipateGB({ my: false })
        .then((res) => {
          if (res && res.code == 1) {
            console.log(res.data);
            this.gbList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onUserTypeChange(e) {
      if (e == 1 || e == 2) {
        this.editForm.qy = "仅餐饮";
      }
      if (e == 3 || e == 4) {
        this.editForm.qy = "全部承担";
      }

      if (e == 8 || this.userTypeIdBak == 8) {
        this.editForm.hyIds = [];
      }

      if (e == 5) {
        this.editForm.hyIds = [1];
      }

      this.userTypeIdBak = e;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.avatarUploadLoadingDiv.close();
      if (res.code == 1) {
        // this.$message({
        //   message: '文件上传成功',
        //   type: 'success'
        // });
        this.editForm.faceImg = res.data;
        return;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    beforeAvatarUpload() {
      this.avatarUploadLoadingDiv = util.showLoading("照片上传中");
    },
    handleAvatarError() {
      this.avatarUploadLoadingDiv.close();
      this.$message({
        message: "照片上传失败,请检查网络",
        type: "warning",
      });
    },
    onUploadSuccess(res, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();

      if (res.code == 0) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
      } else if (res.code == 1) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          this.getDataList(1);
          this.excelImp.dialogVisible = false;
        });
      }
    },
    onUploadError(err, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();
      this.$alert("导入失败", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      });
    },
    submitUpload() {
      this.excelImp.loading = true;
      this.excelImp.loadingDiv = util.showLoading("导入中");
      this.$refs.upload.submit();
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    submitSH(index, row) {
      this.$confirm("嘉宾姓名: [" + row.username + "] ,确认审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loadingDiv = util.showLoading("设置审核通过中");
          shenhe({ pid: row.id, id: row.id, status: 1 })
            .then((res) => {
              if (res.code == 0) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.getDataList(this.currentPage);
                });
              }
              loadingDiv.close();
            })
            .catch((res) => {
              loadingDiv.close();
            });
        })
        .catch(() => {});
    },
    showEditSortBy(row, index) {
      const loadingDiv = util.showLoading("修改排序中...");
      this.editSortBy.pid = row.id;
      this.editSortBy.sortByEditValue = row.sortBy;
      this.$prompt("请输入排序", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /(^[1-9]\d*$)/,
        inputErrorMessage: "请输入正整数",
        inputValue: row.sortBy,
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          updateSortBy({
            pid: this.editSortBy.pid,
            id: this.editSortBy.pid,
            sortBy: value,
          })
            .then((res) => {
              loadingDiv.close();
              if (res.code == 0) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then((res) => {
                  this.getDataList(this.currentPage);
                });
              }
            })
            .catch(() => {
              loadingDiv.close();
              this.$alert("修改失败,请检查您的网络", {
                dangerouslyUseHTMLString: true,
                type: "warning",
              });
            });
        })
        .catch(() => {
          loadingDiv.close();
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });
        });
    },
    submitEditDL() {
      console.log("修改抵离信息");
      console.log(this.editDLForm);
      const loadingDiv = util.showLoading("修改抵离信息中");

      if (util.isNotEmpty(this.dlDate.date1)) {
        this.editDLForm.esArriveTime = this.dlDate.date1 / 1000;
      }
      if (util.isNotEmpty(this.dlDate.date2)) {
        this.editDLForm.arriveTime = this.dlDate.date2 / 1000;
      }
      if (util.isNotEmpty(this.dlDate.date3)) {
        this.editDLForm.backTime = this.dlDate.date2 / 1000;
      }

      updateDLInfo(this.editDLForm)
        .then((res) => {
          if (res.code == 0) {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then(() => {
              this.editDLFormVisible = false;
              this.getDataList(this.currentPage);
            });
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    handleEditDL(index, row) {
      this.editDLForm.pid = row.id;
      this.editDLForm.id = row.id;

      this.editDLForm.username = row.username;
      this.editDLForm.phone = row.phone;
      this.editDLForm.gender = row.gender;

      // 预计抵青时间
      this.editDLForm.esArriveTime = row.esArriveTime;
      if (util.isNotEmpty(row.esArriveTime)) {
        this.dlDate.date1 = row.esArriveTime * 1000;
      }

      // 抵青时间
      this.editDLForm.arriveTime = row.arriveTime;
      if (util.isNotEmpty(row.arriveTime)) {
        this.dlDate.date2 = row.arriveTime * 1000;
      }

      this.editDLForm.arriveMode = row.arriveMode; // 到达方式 '飞机', '高铁', '自驾'
      this.editDLForm.carnum = row.carnum; // 车牌号
      this.editDLForm.frequency = row.frequency; // 班次
      this.editDLForm.arriveStation = row.arriveStation; // 抵青接站口

      // 离青时间
      this.editDLForm.backTime = row.backTime;
      if (util.isNotEmpty(row.backTime)) {
        this.dlDate.date3 = row.backTime * 1000;
      }

      this.editDLForm.backMode = row.backMode; // 离青方式
      this.editDLForm.backCarnum = row.backCarnum; // 离青车牌号
      this.editDLForm.backFrequency = row.backFrequency; // 离青班次
      this.editDLForm.lkdd = row.lkdd; // 离青送站口
      this.editDLForm.dqqy = row.qy; // 权益
      this.editDLForm.hotelId = row.hotelId; // 分配酒店
      this.editDLForm.eatHotelId = row.eatHotelId; // 分配吃饭地点
      this.editDLForm.jdNote = row.jdNote; // 酒店床位备注
      this.editDLForm.xingchengZt = row.xingchengZt; // 行程状态
      this.editDLForm.xingchengZtCurrent = row.xingchengZt; // 修改之前行程状态
      this.editDLForm.dlNote = row.dlNote; // 抵离备注

      this.editDLFormVisible = true;
    },
    getXingchengZtDisabled(label) {
      if (util.isEmpty(this.editDLForm.xingchengZtCurrent)) {
        return false;
      }

      let labelIndex = -1;
      let currentIndex = -1;
      for (let i in this.xingchengZtList) {
        if (this.xingchengZtList[i].label == label) {
          labelIndex = i;
        }
        if (
          this.xingchengZtList[i].label == this.editDLForm.xingchengZtCurrent
        ) {
          currentIndex = i;
        }
      }

      if (labelIndex < currentIndex) {
        return true;
      }
      return false;
    },
    sendYYSMS() {
      let loadingDiv;
      this.$confirm("确认发送短信邀请函？", "发送确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("发送邀请短信中");
            return sendYYSMS({
              pid: this.yyInfoRow.id,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          this.$alert(res.msg);
        })
        .catch((err) => {
          loadingDiv.close();
          this.$alert("发送失败,请检查网络。");
        });
    },
    sendYYEmail() {
      let loadingDiv;
      this.$confirm("确认发送邀请邮件？", "发送确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("发送邀请邮件中");
            return sendYYEmail({
              pid: this.yyInfoRow.id,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          this.$alert(res.msg);
        })
        .catch((err) => {
          loadingDiv.close();
          this.$alert("发送失败,请检查网络。");
        });
    },
    sendYQSMSBatch() {
      let selection = this.$refs.elTable.selection;
      if (!selection || selection.length <= 0) {
        this.$alert("请选择嘉宾");
        return;
      }

      let pids = [];
      for (let i = 0; i < selection.length; i++) {
        pids.push(selection[i].id);
      }

      let loadingDiv;
      this.$confirm("确认批量发送短信邀请函？", "发送确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("发送邀请短信中");
            return sendYYSMSBatch({
              pids: pids,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          this.$alert(res.msg);
        })
        .catch((err) => {
          loadingDiv.close();
          this.$alert("发送失败,请检查网络。");
        });
    },
    sendYQEmailBatch() {
      let selection = this.$refs.elTable.selection;
      if (!selection || selection.length <= 0) {
        this.$alert("请选择嘉宾");
        return;
      }

      let pids = [];
      for (let i = 0; i < selection.length; i++) {
        pids.push(selection[i].id);
      }

      let loadingDiv;
      this.$confirm("确认批量发送邮件邀请函？", "发送确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("发送邀请邮件中");
            return sendYYEmailBatch({
              pids: pids,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          this.$alert(res.msg);
        })
        .catch((err) => {
          loadingDiv.close();
          this.$alert("发送失败,请检查网络。");
        });
    },
    saveImg() {
      let a = document.createElement("a");
      a.href = this.yyhImg;
      a.setAttribute("download", this.genYYHRow.username + "的邀请函");
      a.click();
    },
    genYYH(index, row) {
      this.yyhImg = "";
      this.genYYHRow = row;
      this.genImgTitle = "邀请函生成中";
      this.yyInfoCode = "";
      this.yyInfoSMS = "";
      this.yyInfoSendStatus = "";
      this.yyInfoRow = row;

      getInvitationCode({ p_id: row.id }).then((res) => {
        if (res.code != 1 || util.isEmpty(res.data)) {
          this.$alert(res.msg, {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
          return;
        }

        this.yyInfoCode = res.data.code;
        this.XCXScanTime = res.data.XCXScanTime;
        this.H5ScanTime = res.data.H5ScanTime;
        this.yyInfoSMS =
          "【青岛影视周】尊敬的" +
          row.username +
          "，诚邀您参加·2023青岛影视周，访问 y.qdysblh.com/#/?code=" +
          this.yyInfoCode +
          " 可以反馈您的参会行程，期待您的莅临。";

        this.painting.views = [
          {
            type: "image",
            url: "https://cdn.ybh.gengduoke.com/yaoqinghan3.jpg",
            css: {
              width: `1080px`,
              height: `1890px`,
              top: "0",
            },
          },
          {
            type: "qrcode",
            content: "https://y.qdysblh.com/" + this.yyInfoCode,
            css: {
              top: `1330px`,
              left: `385px`,
              color: "#000",
              width: `320px`,
              height: `320px`,
              borderColor: "#FFF",
              borderWidth: "15px",
              background: "#FFF",
            },
          },
          {
            type: "text",
            text: row.username,
            css: {
              top: `609px`,
              left: `230px`,
              color: "#FFF",
              fontSize: "37px",
            },
          },
        ];

        this.genPoster = true;
        this.yyhDialogVisible = true;
      });
    },
    genYYHSuccess(val) {
      this.genImgTitle = "邀请函生成成功";
      this.genPoster = false;
      this.yyhImg = val;
      if (this.genYYHLoadingDiv) {
        this.genYYHLoadingDiv.close();
      }
    },
    genYYHFail(val) {
      this.genImgTitle = "";
      this.genPoster = false;
      this.$alert("生成邀请函失败");
      this.yyhDialogVisible = false;
    },
    handleCheckAllChange(val) {
      if (val) {
        let ids = [];
        for (let i in this.plateList) {
          ids.push(this.plateList[i].id);
        }
        this.editForm.hyIds = ids;
      } else {
        this.editForm.hyIds = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.plateList.length - 1;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.plateList.length - 1;
    },
    handleAddressChange() {
      if (this.selectValue.length == 2) {
        this.editForm.sheng = this.selectValue[0];
        this.editForm.shi = this.selectValue[0];
        this.editForm.xian = this.selectValue[1];
      } else {
        this.editForm.sheng = this.selectValue[0];
        this.editForm.shi = this.selectValue[1];
        this.editForm.xian = this.selectValue[2];
      }
    },
    clear() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      for (let key in this.date) {
        this.date[key] = "";
      }
      this.searchForm.userTypeIdList = [];
      this.searchForm.userTypeId = [];

      this.getDataList(1);
    },
    init() {
      getUserTypeList().then((res) => {
        if (res.code) {
          this.userTypeList = res.data;
          this.userTypeList.unshift({ name: "请选择嘉宾类型", id: "" });
        }
      });

      // getPlateList().then(res=>{
      //   if (res.code){
      //     this.plateList = res.data;
      //     this.plateList.unshift({title: "请选择会议", id:''})
      //   }
      // });
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
          this.yyCompanyList.unshift({ name: "请选择邀宾单位/部门", id: "" });
        }
      });

      getSystemSet({ group: "work_group" }).then((res) => {
        if (res.code) {
          // this.yyCompanyList = res.data;
          this.workGroupList.unshift({ title: "请选择部门/组", value: "" });
          for (let i in res.data) {
            this.workGroupList.push({
              title: res.data[i].title,
              value: res.data[i].title,
            });
          }
        }
      });

      getHotelList().then((res) => {
        if (res.code) {
          this.hotelList = res.data;
          this.hotelList.unshift({ id: "", name: "请选择酒店" });
        }
      });

      getAllUserList().then((res) => {
        if (res.code) {
          this.userList = JSON.parse(JSON.stringify(res.data));
          this.handlerUserList = res.data;

          this.userList.unshift({ id: "", nickname: "请选择管理员" });
          this.handlerUserList.unshift({ id: "", nickname: "请选择联络员" });
        }
      });

      // 按照分组获取会议
      getPlateListWithGroup({ isZy: 0 }).then((res) => {
        this.plateWithGroupList = res.data;
        if (res.code) {
          this.tabsActiveName = res.data[0].title;
        }
      });

      // 按照分组获取展映
      getPlateListWithGroup({ isZy: 2 }).then((res) => {
        this.zyPlateList = res.data;
        if (res.code) {
          this.zyTabsActiveName = this.zyPlateList[0].title;
        }
      });
    },
    parsePlate(row, status) {
      if (util.isNotEmpty(row.plateList)) {
        let str = "";
        let index = 1;
        let plateList = row.plateList;
        for (let i in plateList) {
          if (util.isEmpty(plateList[i])) {
            continue;
          }
          str +=
            "<div class='ellipsis'>" +
            index +
            ".<" +
            plateList[i].title +
            "></div>";
          index++;
        }

        if (status == 1) {
          if (row.status == 1 || row.status == 3) {
            return str;
          }
        } else if (status == 0) {
          if (row.status == 2) {
            return str;
          }
        }
      } else {
        return "";
      }
    },
    fmtDate2(d2, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2) || d2 <= 0) {
        return "";
      }
      return this.fmtDate(new Date(d2 * 1000), fmt);
    },
    fmtDate(d, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (!fmt) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        S: d.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    showAddDialogForm() {
      this.getParticipateGB();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }

      if (this.frontUserList && this.frontUserList.length <= 0) {
        getFrontUserList().then((res) => {
          if (res.code) {
            this.frontUserList = res.data;
          }
        });
      }

      for (let key in this.editForm) {
        this.editForm[key] = "";
      }
      this.addShowFlag = false;
      this.editForm.id = 0;
      this.editForm.country = "中国";
      this.editForm.idCardType = 0;
      this.editForm.hyIds = [];
      this.selectValue = "";
      this.tabsActiveName = this.plateWithGroupList[0].title;

      this.editFormVisible = true;
    },
    handleEdit(index, row) {
      this.getParticipateGB();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }

      if (this.frontUserList && this.frontUserList.length <= 0) {
        getFrontUserList().then((res) => {
          if (res.code) {
            this.frontUserList = res.data;
          }
        });
      }

      this.addShowFlag = true;
      this.editForm.id = row.id;
      this.editForm.frontUserId = row.frontUserId;
      this.editForm.handlerId = row.handlerId;
      this.editForm.username = row.username;
      this.editForm.phone = row.phone;
      this.editForm.gender = row.gender;
      this.editForm.company = row.company;
      this.editForm.post = row.post;
      this.editForm.yslingdao = row.yslingdao;
      this.editForm.ysdesc = row.ysdesc;
      this.editForm.country = row.country;
      this.editForm.idcard = row.idcard;
      this.editForm.idCardType = row.idCardType;
      this.editForm.sheng = row.sheng;
      this.editForm.shi = row.shi;
      this.editForm.xian = row.xian;
      this.editForm.email = row.email;
      this.editForm.phoneType = row.phoneType;
      this.editForm.faceImg = row.faceImg;
      this.editForm.ppid = row.ppid;
      this.editForm.qyOther = row.qyOther;
      this.editForm.note2 = row.note2;

      if (row.sheng == row.shi) {
        this.selectValue = [row.sheng, row.xian];
      } else {
        this.selectValue = [row.sheng, row.shi, row.xian];
      }

      let hyIds = [];
      if (row.plateList && row.plateList.length > 0) {
        for (let i in row.plateList) {
          hyIds.push(row.plateList[i].plateId);
        }
      }
      this.editForm.hyIds = hyIds;
      this.editForm.userTypeId = row.userTypeId;
      this.editForm.yyCompanyId = row.yyCompanyId;
      this.editForm.workGroup = row.workGroup;
      this.editForm.qy = row.qy;
      this.editForm.note = row.note;
      this.editForm.status = row.status;
      this.editForm.yq = row.yq;
      this.editForm.yyType = row.yyType;
      this.tabsActiveName = this.plateWithGroupList[0].title;

      this.editFormVisible = true;
    },
    a(e) {
      console.log(e);
      console.log(this.searchForm.userTypeId);
    },
    submitForm() {
      if (this.editForm.userTypeId == 6 && util.isEmpty(this.editForm.ppid)) {
        this.$message.error("请选择贵宾");
        return;
      }

      if (this.editForm.userTypeId != 6) {
        this.editForm.ppid = "";
      }

      if (this.editForm.status == 3) {
        if (util.isEmpty(this.editForm.idcard)) {
          this.$message.error("请填写证件号码");
          return;
        }
      }

      if (util.isNotEmpty(this.editForm.phone)) {
        if (util.isEmpty(this.editForm.phoneType)) {
          this.$message.error("请选择电话类型");
          return;
        }
      }

      this.$refs.editForm.validate((valid, val) => {
        if (!valid) {
          let msg = "";
          for (let key in val) {
            msg = val[key][0].message;
            break;
          }
          this.$message.error(msg);
        }

        if (this.editForm.qy == "其他") {
          if (util.isEmpty(this.editForm.qyOther)) {
            this.$message.error("请填写付费方式");
            return;
          }
        } else {
          this.editForm.qyOther == "";
        }

        if (valid) {
          this.editForm.hyId = this.editForm.hyIds.join(",");

          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = updateParticipate;
          if (this.editForm.id == 0) {
            func = addParticipate;
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  let pNum = 1;
                  this.editFormVisible = false;
                  if (this.editForm.id > 0) {
                    pNum = this.currentPage;
                  }
                  this.getDataList(pNum);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$prompt("请输入删除原因", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (util.isEmpty(value)) {
            this.$message.error("请填写删除原因");
            this.handleDelete(index, row);
            return;
          } else {
            let loadingDiv = util.showLoading("删除中...");
            deleteParticipate({ pid: row.id, deleteReason: value })
              .then((res) => {
                loadingDiv.close();
                // console.log("===================")
                // console.log(res)
                if (res.code == 0) {
                  this.$alert(res.msg, "删除失败", {
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    showClose: false,
                  });
                } else if (res.code == 1) {
                  this.$alert(res.msg, "删除成功", {
                    dangerouslyUseHTMLString: true,
                    type: "success",
                    showClose: false,
                  }).then((res) => {
                    this.editFormVisible = false;
                    this.getDataList(this.currentPage);
                  });
                }
              })
              .catch((res) => {
                if (loadingDiv) {
                  loadingDiv.close();
                }
              });
          }
        })
        .catch((res) => {
          // if (loadingDiv){
          //   loadingDiv.close();
          // }
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      };

      if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
        this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
        this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
      } else {
        this.searchForm.arriveTime1 = "";
        this.searchForm.arriveTime2 = "";
      }

      if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
        this.searchForm.backTime1 = this.date.date2[0] / 1000;
        this.searchForm.backTime2 = this.date.date2[1] / 1000;
      } else {
        this.searchForm.backTime1 = "";
        this.searchForm.backTime2 = "";
      }

      if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
        this.searchForm.updateTime1 = this.date.date3[0] / 1000;
        this.searchForm.updateTime2 = this.date.date3[1] / 1000;
      } else {
        this.searchForm.updateTime1 = "";
        this.searchForm.updateTime2 = "";
      }

      if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
        this.searchForm.createTime1 = this.date.date4[0] / 1000;
        this.searchForm.createTime2 = this.date.date4[1] / 1000;
      } else {
        this.searchForm.createTime1 = "";
        this.searchForm.createTime2 = "";
      }

      for (var i in this.searchForm) {
        param[i] = this.searchForm[i];
      }

      getParticipateList(param)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            console.log("参会人员");
            let data = res.data;
            this.plateList = data.plateList;
            this.plateList.unshift({ title: "请选择会议", id: "" });
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
            if (data.plateList) {
              this.plateList = data.plateList;
            }
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDataList(1);
    },
    exportExcel() {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let param = {};

      if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
        this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
        this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
      }

      if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
        this.searchForm.backTime1 = this.date.date2[0] / 1000;
        this.searchForm.backTime2 = this.date.date2[1] / 1000;
      }

      if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
        this.searchForm.updateTime1 = this.date.date3[0] / 1000;
        this.searchForm.updateTime2 = this.date.date3[1] / 1000;
      }

      if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
        this.searchForm.createTime1 = this.date.date4[0] / 1000;
        this.searchForm.createTime2 = this.date.date4[1] / 1000;
      }

      for (var i in this.searchForm) {
        param[i] = this.searchForm[i];
      }

      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/participate/export-excel", // 请求地址
        data: param, // 参数
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          console.log(res);
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");
          link.download = "按搜索条件导出嘉宾_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },
    exportExcelNew(num) {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let param = { num: num };
      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/participate/export-excel-new", // 请求地址
        data: param, // 参数
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

          let fileName = "2023青岛影视周嘉宾信息表";
          switch (num) {
            case 10:
              fileName = "2023青岛影视周嘉宾信息表";
              break;
            case 11:
              fileName = "2023青岛影视周政务贵宾信息表";
              break;
            case 12:
              fileName = "2023青岛影视周政务嘉宾信息表";
              break;
            case 13:
              fileName = "2023青岛影视周行业贵宾信息表";
              break;
            case 14:
              fileName = "2023青岛影视周行业嘉宾信息表";
              break;
          }

          link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tabs--border-card {
  box-shadow: none;
}
</style>
